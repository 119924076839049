@import '~antd/lib/style/themes/default.less';
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@400;700&display=swap");


@font-family: "Open Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

// ====
//from Refine base theme

// base variables
@border-radius-base: 24px;

// colors
@primary-color: transparent; // defined in craco.config.js
@error-color: transparent; // defined in craco.config.js
@text-color: transparent; // defined in craco.config.js
@info-color: transparent; // defined in craco.config.js
@background-color: transparent; // defined in craco.config.js
@primary-gradient: linear-gradient(90deg, @info-color, @primary-color);
