@import './theme/index.less';
@import '~antd/dist/antd.less';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// ====
// from Refine base theme

// variables overrides
@layout-body-background: @background-color;

// sider
@layout-sider-background: rgba(0, 0, 0, 1);
@menu-bg: transparent;
@menu-inline-submenu-bg: transparent;
@menu-item-color: #fff;
@menu-highlight-color: #fff;
@menu-item-active-bg: transparent;
@menu-item-active-border-width: 0px;
@layout-trigger-background: rgba(0, 0, 0, 0.5);

//form
@label-color: @text-color;

// checkboxes
@checkbox-border-radius: 4px;

// classes overrides
.ant-btn-primary {
    background: @primary-gradient;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: 0px;
}

.ant-form-item-label > label {
    font-weight: bold;
}

.ant-page-header {
    border-radius: @border-radius-base;
}


// AteTableRow
// TODO : move to its own component directory
.AteTableRow.-disabled {
    background-color: @disabled-color;
    opacity: 0.50;

    &:hover {
        opacity: 0.8;
        &>.ant-table-cell {
        background-color: @disabled-color;
        
    }}
}

// Disabled style for buttons (default text color is same as background)
.ant-btn[disabled], .ant-btn[disabled]:hover {
    color: @text-color;
    opacity: 0.6;
}

.AteClickable {
    cursor: pointer;
}
@primary-color: rgba(252, 145, 50, 1);@error-color: rgba(250, 0, 54, 1);@text-color: rgba(0, 0, 0, 0.8);@info-color: rgba(182, 17, 199, 1);@background-color: rgba(249, 248, 249, 1);@disabled-color: rgba(236, 235, 236, 1);