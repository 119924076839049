@import './theme/index.less';

.AteLayoutSider {
  height: 100vh;
  position: sticky !important;
  top: 0;
  overflow-y: scroll;
}

.AteLayoutSider > .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.AteMenu {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.ant-menu-item-group-title {
  color: white !important;
}

.AteMenuItem {
  padding-left: 16px !important;
}

.AteMenuItem.-active {
  background: @primary-gradient;
}

.AteMenuItem.AteMenuItem.-logoutButton {
  margin-top: auto;
}

@primary-color: rgba(252, 145, 50, 1);@error-color: rgba(250, 0, 54, 1);@text-color: rgba(0, 0, 0, 0.8);@info-color: rgba(182, 17, 199, 1);@background-color: rgba(249, 248, 249, 1);@disabled-color: rgba(236, 235, 236, 1);